.ant-modal-mask {
    backdrop-filter: blur(0.5px);
    background-color: #f4f5f8dd !important;
}

.ant-modal-content {
    background-color: #f4f5f8 !important;
}

.ant-modal-header {
    background: rgba(0, 0, 0, 0);
}
