.report-widget {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;

    &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100%;
        overflow: visible;
        flex: 1;

        @media screen and (max-width: 768px) {
            gap: 6px;
        }
    }

    &__content {
        flex: 1;
        display: flex;
        border-radius: 24px;
        background: #fff;
        border: 1px solid #eff0f6;
        padding: 12px 12px 4px 12px;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            border-radius: 24px 24px 0 0;
        }
    }

    &__card {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            border-radius: 12px;
        }
    }
}
