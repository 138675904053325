.actionButton {
    color: var(--ant-color-text-secondary);
    background-color: transparent;
    font-size: inherit;
    transition: 0.4s;
    font-weight: 400;
    border: none;
    // border-bottom: none !important;
    box-shadow: none;
    padding: 4px 8px;
    cursor: pointer;
    animation: btnTransition 1s forwards;
    min-width: max-content;

    &:hover:not(:disabled) {
        color: var(--ant-color-primary);
    }

    &:disabled {
        opacity: 0.5 !important;
        cursor: not-allowed;
    }
}

.tabControlContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 16px;
}

.tabControlActions {
    display: flex;
    align-items: center;
    gap: 12px;
}

.splitViewButton {
    background: #f4f5f8;
    border: none;
    cursor: pointer;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6e7191;
    transition: all 0.3s ease;

    &:hover {
        background-color: #f7f7fc;
    }

    &.active {
        color: #4e4b66;
        background-color: #f7f7fc;
    }

    //@media screen and (max-width: 1224px) {
    //    visibility: collapse;
    //    width: 0;
    //}
}

.adminModeContainer {
    background: #FFEBE0;
    font-weight: 500;
    cursor: default;
    color: #FF6E26;
    padding: 12px 16px;
    border-radius: 16px;
    border: 1px solid #FFD1BB;
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: row;
    
}

.contentContainer {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 0;

    &.splitView {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.primaryContent,
.secondaryContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 0;
    transition: flex 0.1s ease;
    position: relative;
}

.primaryContent {
    // padding-right: 12px;

    @media screen and (max-width: 768px) {
        padding-right: 0;
    }
}

.secondaryContent {
    padding-left: 12px;
    padding-right: 3px;

    @media screen and (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.secondaryTabControl {
    margin-bottom: 16px;
}

.divider {
    flex: 0 0 3px;
    //width: 100px;
    background-color: #eff0f6;
    margin: 0;
    position: relative;
    cursor: col-resize;
    transition: background-color 0.2s ease;
    z-index: 10;

    &:hover,
    &.dragging {
        background-color: #d9dbe9;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background-color: #d9dbe9;
        border-radius: 50%;
        display: none;
    }

    &:hover::after {
        display: block;
    }

    &.dragging::after {
        display: block;
        background-color: #a0a3bd;
    }
}

.segmentedOption {
    width: fit-content;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
}

.segmentedContainer {
    overflow-x: scroll; /* включаем горизонтальный скролл */
    //height: 46px;
}

.tabLabel {
    display: flex;
    align-items: center;
    gap: 6px;
}

.setupIcon {
    display: flex;
    align-items: center;
    overflow: visible;
    // stroke: currentColor;
    // stroke-width: 0.5px;
}

.tabContainer {
    display: flex;
    flex-direction: column;
    // justify-items: center;
    // align-items: center;
    height: 100%;
    // max-width: 800px;
    position: relative;
    //overflow: hidden;

    .contentWrapper {
        width: 100%;
        padding-top: 24px; // 24px;
        // position: relative;

        height: 100%;
        overflow-y: auto;
        display: flex;
        justify-content: center;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-track {
            background: #e5e5ea70;
            transform: translateX(10px);
        }

        &::-webkit-scrollbar-thumb {
            background: #e5e5ea70;
            border-radius: 3px;
        }

        /* Для Firefox */
        scrollbar-width: thin;
        scrollbar-color: #e5e5ea70 #f1f1f100;
    }

    .tabContent {
        // overflow-y: auto;
        padding-top: 12px;
        margin: 0 auto;
        justify-items: center;
        // align-items: center;
        width: 100%;
        max-width: 800px;
        height: 100%;
        //margin: 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        //flex: 1;
        //minHeight: 0;
    }

    .anchorIcon {
        display: inline-flex;
        align-items: center;
        margin-right: 2px;
        overflow: visible;
    }
}

.projectTitle {
    color: var(--ant-color-text) !important;
    font-size: 24px !important;
    //line-height: 28px !important;
    font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif !important;
    font-weight: 700 !important;
    margin-top: 0 !important;
    margin-bottom: 16px !important;
}

.anchorContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-top: 8px;
    margin-bottom: 8px;
    flex-wrap: wrap;
    gap: 4px;

    @media screen and (max-width: 768px) {
        justify-content: flex-start;
        width: 100%;

        & > :first-child {
            width: 100%;
            overflow-x: auto;
        }
    }
}

.expandButton {
    background: none;
    border: none;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--ant-color-text-secondary);
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;

    &:hover {
        color: var(--ant-color-primary);
    }

    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: 2px solid var(--ant-color-primary);
        outline-offset: 2px;
        border-radius: 2px;
    }

    &:active {
        transform: scale(0.95);
        opacity: 0.8;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.gradientOverlayTop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 36px;
    // transform: rotate(180deg); /* Переворачиваем блок на 180 градусов */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0) 100%);

    pointer-events: none;
    z-index: 5;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        /* Нелинейная маска: 
           - от 0% до 30% – без размытия (прозрачно),
           - от 30% до 60% – плавный переход,
           - от 60% до 100% – полное размытие */
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0) 100%);
        -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0) 100%);
        pointer-events: none;

        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0) 100%);

        @media screen and (max-width: 768px) {
            backdrop-filter: none;
            -webkit-backdrop-filter: none;
        }
    }
}

.gradientOverlayBottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 30%,
        rgba(255, 255, 255, 0.95) 80%,
        rgba(255, 255, 255, 1) 90%,
        rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    z-index: 5;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        /* Нелинейная маска: 
           - от 0% до 30% – без размытия (прозрачно),
           - от 30% до 60% – плавный переход,
           - от 60% до 100% – полное размытие */
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 1) 100%);
        -webkit-mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 30%,
            rgba(0, 0, 0, 0.8) 60%,
            rgba(0, 0, 0, 1) 100%
        );

        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 70%, rgba(255, 255, 255, 1) 100%);
        pointer-events: none;

        @media screen and (max-width: 768px) {
            backdrop-filter: none;
            -webkit-backdrop-filter: none;
        }
    }
}

.fixedReportGenerateMenu {
    position: fixed;
    width: 100%;
    bottom: 20px;
    z-index: 100;
}

.referralContainer {
    position: fixed;
    left: 22px;
    bottom: 22px;
    z-index: 101;

    @media screen and (max-width: 1224px) {
        display: none;
    }
}

.tabsRow {
    display: flex;
    padding-right: 16px;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;
    width: 100%;

    &::-webkit-scrollbar {
        height: 0;
        display: none;
    }

    scrollbar-width: none;
}

.tabsGroup {
    display: flex;
    align-items: center;
}
