.informer {
    width: 100%;
    padding: 11px 12px 14px 12px;
    background: #F5FAFE;
    border-radius: 12px;
    border: 1px solid #c7dcf0d2;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
  
    .informer-content {
      display: inline-flex;
      align-self: stretch;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
  
      .icon {
        color: #1480E6;
        transform: translateY(1px);
      }
  
      .text {
        flex: 1 1 0;
        color: #1480E6;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        word-wrap: break-word;
      }
    }
  }

.informer.spinning {
  .informer-content {
    .icon {
      animation: spin 6s linear infinite;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning .informer-content .icon {
  animation: spin 6s linear infinite;
}

/* Новый стиль для изменения обводки при ховере на весь информер */
// .informer:hover {
//   border-color: #097B5D;
//   transition: border-color 0.3s ease;
// }