.settingsContent {
    min-width: 200px;
    padding: 0 0;
}

.settingsSection {
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }

    h4 {
        font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
        margin-bottom: 4px;
        font-weight: 500;
    }
}

.settingRow {
    font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.settingsButton {
    background: rgba(255, 255, 255, 0.7); // Полупрозрачный белый фон
    //backdrop-filter: blur(8px);
    border: none;
    padding: 8px;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
    position: absolute;
    right: 0;
    z-index: 101;

    &:hover {
        color: var(--ant-color-primary);
    }
}
