.referralContainer {
    width: 230px;
    height: 92px;
    border-radius: 18px;
    padding: 14px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    transition: height 0.4s ease;
    overflow: hidden;
    position: relative;
    background-color: #FFFFFF;

    &.expanded {
        height: 180px;
        background-color: #F8F8FF;
        border: 1px solid #eff0f6
    }
}

.titleContainer {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-left: 10px;

    .title {
        font-size: 14px;
        font-weight: 500;
        color: #4b5563;
    }
}

.description {
    font-size: 14px;
    color: #3D3D3D;
    margin: 0;
    padding-left: 10px;
    padding-right: 4px;
    line-height: 1.4;
}

.copyButton {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: #F8F8FF;
    border: 1px solid #E6E6FF;
    border-radius: 12px;
    padding: 12px 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;

    &.expanded {
        background-color: #2B2B30;
        color: white;
        padding: 12px 10px;
    }

    &.copied {
        background-color: #F5FEFA;
        border-color: #C7F0E2;
        color: #097B5D;
    }

    &:hover {
        // transform: translateY(-1px);
    }

    &:active {
        transform: translateY(1px);
    }
}

.buttonContent {
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 6px;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
}

.codeText {
    display: flex;
    align-items: start;
    justify-content: start;
    font-size: 13px;
    margin-top: 4px;
    padding-left: 20px;
    color: #bdc1c9;

    &.copied {
        color: #097B5D;
    }

}
