.aseedButton {
    font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
    background-color: var(--ant-color-primary);
    font-size: 16px;
    color: white;
    font-weight: 450;
    border: none;
    border-radius: 50px;
    padding: 14px 18px 14px 14px; /* базовый паддинг */
    display: flex;
    align-items: center;
    gap: 0px;
    // box-shadow:
    //   0px 6px 12px 0px rgba(16, 16, 16, 0.2),
    //   0px 138px 39px 0px rgba(0, 0, 0, 0),
    //   0px 89px 35px 0px rgba(0, 0, 0, 0.01),
    //   0px 50px 30px 0px rgba(0, 0, 0, 0.02);
    // backdrop-filter: blur(5px);
    transition: background-color 0.2s, transform 0.2s, padding 0.2s ease-in-out, gap 0.2s ease-in-out;
    cursor: pointer;
    overflow: hidden;
  
    &:hover {
      background-color: #3a3a3d;
      padding: 14px; /* равномерное увеличение отступов при ховере */
      gap: 8px; /* анимация увеличения расстояния между элементами */
    }
  
    &:active {
      background-color: #d0c7ff;
    }
  
    &:disabled {
      background-color: grey;
      cursor: not-allowed;
    }
  }
  
  .aseedButtonNoHover {
    cursor: default; /* обычная стрелка */
    transition: none; /* отключаем анимацию */
  
    /* Переопределяем состояния hover и active */
    &:hover,
    &:active {
      background-color: var(--ant-color-primary) !important;
      padding: 14px 18px 14px 14px !important;
      gap: 0 !important;
    }
  }
  
  .noRightIcon {
    &:hover {
      /* При отсутствии правой иконки оставляем паддинг и gap неизменными */
      padding: 14px 18px 14px 14px !important;
      gap: 0 !important;
    }
  }

  .RightIcon {
    &:hover {
      /* При отсутствии правой иконки оставляем паддинг и gap неизменными */
      padding: 14px 14px 14px 14px !important;
      gap: 8px !important;
    }
  }

  .buttonContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  
  .leftIcon {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .buttonText {
    display: inline-block;
  }
  
  .chevronRightIcon {
    opacity: 0;
    visibility: hidden;
    width: 0;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, width 0.2s ease-in-out;
  }
  
  .aseedButton:hover .chevronRightIcon {
    opacity: 1;
    visibility: visible;
    width: 16px;
  }