.menuWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    // transition: all 0.2s ease;
    // margin-bottom: 22px;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonStyle {
    font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
    background-color: var(--ant-color-primary);
    font-size: 16px;
    color: white;
    font-weight: 450;
    border: none;
    border-radius: 50px;
    padding: 14px 0px 14px 14px; /* Немного увеличил отступы для лучшего эффекта */
    display: flex;
    align-items: center;
    gap: 0; /* Начальное расстояние между текстом и иконкой */
    box-shadow:
        0px 6px 12px 0px rgba(16, 16, 16, 0.2),
        0px 138px 39px 0px rgba(0, 0, 0, 0),
        0px 89px 35px 0px rgba(0, 0, 0, 0.01),
        0px 50px 30px 0px rgba(0, 0, 0, 0.02);
    backdrop-filter: blur(5px);
    transition:
        background-color 0.2s,
        transform 0.2s,
        padding 0.2s ease-in-out,
        gap 0.2s ease-in-out; /* Добавил анимацию gap */
    cursor: pointer;

    &:hover {
        background-color: #3a3a3d;
        padding: 14px 14px 14px 14px; /* Увеличиваем ширину кнопки при ховере */
        gap: 8px; /* Плавное появление отступа для иконки */
    }

    &:active {
        background-color: #d0c7ff;
    }

    &:disabled {
        background-color: grey;
        cursor: not-allowed;
    }
}

.chevronRightIcon {
    opacity: 0;
    visibility: hidden;
    width: 0;
    transition:
        opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out,
        width 0.2s ease-in-out;
}

.buttonStyle:hover .chevronRightIcon {
    opacity: 1;
    visibility: visible;
    width: 16px; /* Размер иконки, подстроить по нужному значению */
}

.tokenText {
    margin-top: 6px;
    font-size: 11px;
    font-weight: normal;
}
