/* Заменяет inline-стиль у <Layout style={{ minHeight: '100vh' }}> */
.layoutMinHeight {
    /* min-height: 100vh; */
}

.mainContainer {
    height: 100vh;
    display: flex;
    padding: 6px 12px 12px 12px;
    align-items: center;
    gap: 6px;
    flex-direction: column;
}

.header {
    width: 100%;
    height: 42px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.headerInner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

/* Контейнер для логотипа */
.logoContainer {
    /* width: 75px; */
    /* padding: 10px; */
    /* background: white; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* gap: 10px; */
}

/* Стили для текста логотипа */
.logo {
    /* color: black; */
    /* font-size: 16px; */
    /* font-family: 'SF Pro', sans-serif; */
    /* font-weight: 590; */
    /* line-height: 20.8px; */
    /* word-wrap: break-word; */
}

.authContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid #eff0f6;
    background: #fff;
}

.authContent {
    height: 100%;
    width: 100%;
    padding-left: 12px;
    padding-top: 68px;
    overflow-y: scroll;
}

/* Chrome, Safari и Edge */
.authContent::-webkit-scrollbar,
.layout-minHeight::-webkit-scrollbar {
    width: 12px; /* ширина скроллбара */
}

.authContent::-webkit-scrollbar-track,
.layout-minHeight::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
}

.authContent::-webkit-scrollbar-thumb,
.layout-minHeight::-webkit-scrollbar-thumb {
    background-color: #eff0f6; /* цвет ползунка */
    border-radius: 10px; /* скругление углов */
    border: 3px solid #fff; /* отступ между ползунком и дорожкой */
}

.authHeader {
    text-align: center;
    margin-bottom: 24px;
    /* display: flex;  */
    justify-content: center;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.auth-logo {
    width: 120px;
    height: auto;
    margin-bottom: 16px;
}

.auth-form {
    width: 100%;
}

/* Адаптация под мобильные устройства */
@media (max-width: 600px) {
    .auth-content::-webkit-scrollbar,
    .main-container::-webkit-scrollbar {
        display: none;
    }

    .auth-content,
    .main-container {
        scrollbar-width: none;
    }
    .main-container {
        height: fit-content;
    }

    .auth-content {
        overflow-y: hidden;
        height: fit-content;
    }

    .auth-container {
        overflow-y: hidden;
        height: fit-content;
    }

    .auth-header h1 {
        font-size: 24px;
    }

    .auth-header p {
        font-size: 16px;
    }
}
