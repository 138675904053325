.modalContent {
    position: relative;
    padding: 0;

    .insetContainer {
        position: relative;
        border: 0.75px solid #f0f2f5;
        padding: 20px 20px 20px;
        background-color: rgb(255, 255, 255);
        border-radius: 22px;

        .closeButton {
            position: absolute;
            top: 16px;
            right: 16px;
            z-index: 1;
        }

        .title {
            color: #535358;
            font-size: 24px;
            margin-bottom: 12px;
            margin-top: 0;
        }

        .description {
            color: #3d3d3d;
            margin-bottom: 16px;
            font-size: 14px;
        }

        .divider {
            height: 0.5px;
            background-color: #e0e1e4;
            margin: 22px 0;
        }

        .tokenInfo {
            color: #3d3d3d;
            font-size: 12px;
            margin-top: 16px;
        }
    }

    .tokenButtons {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding-bottom: 8px;

        .tokenButton {
            flex: 1 1 30%;
            min-width: 104px;
            height: 34px;
            border: 1px solid transparent !important;
            box-shadow: none !important;
            border-radius: 10px;
            font-size: 14px;
            background-color: #f4f5f8;

            &:hover {
                background-color: #ebe7f9;
                border: 1px solid var(--ant-color-primary) !important;
            }

            &.selected {
                background-color: #2b2b30;
                color: white;
                &:hover {
                    color: #bb97f8;
                    background-color: #2b2b30; /* можно изменить и фон, если нужно */
                }
            }
        }
    }

    .priceInfo {
        padding: 0 20px;
        //margin-top: 24px;

        .priceRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            color: rgba(0, 0, 0, 0.65);
        }

        .totalRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .submitButton {
        // width: calc(100% - 40px);
        background-color: #2b2b30;

        // margin: 12px 12px;
        box-sizing: border-box;
        height: 34px;
        border: none;
        box-shadow: none !important;
        border-radius: 10px;
        font-size: 14px;
    }
}

// Добавляем стили для модального окна
:global(.ant-modal-content) {
    padding: 0 !important;
    overflow: hidden;
}

.paymentSection {
    display: flex;
    flex-direction: column;
    padding: 22px;
    gap: 10px;
}

.paymentRow {
    display: flex;
    flex-direction: column;
    //   gap: 10px;
    width: 100%;
}

.tokenRate {
    display: flex;
    align-items: center;
    gap: 6px;
    //   font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
}

.tokenRateText {
    opacity: 0.6;
    color: #151515;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    word-wrap: break-word;
}

.rateIcon {
    color: #d7d7e6;
    font-size: 11px;
    font-weight: 600;
    line-height: 130%;
    word-wrap: break-word;
}

.totalPayment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.totalText {
    opacity: 0.9;
    color: #151515;
    font-size: 16px;
    //   font-family: 'SF Pro', sans-serif;
    font-weight: 590;
    line-height: 130%;
    word-wrap: break-word;
}

.infoMessage {
    align-self: stretch;
    padding: 12px;
    background: #ebe7f9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    color: #7e52cc;
    font-size: 14px;
    //   font-family: 'SF Pro', sans-serif;
    font-weight: 400;
    line-height: 130%;
    word-wrap: break-word;
}
