.messageBubbleWrapper {
    display: flex;
    //margin-bottom: 14px;

    &.respondent {
        justify-content: flex-start;
    }

    &:not(.respondent) {
        justify-content: flex-end;
    }
}

.messageBubble {
    max-width: 70%;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid transparent;
    transition: all 0.2s ease;
    cursor: pointer;

    @media (max-width: 768px) {
        max-width: 90%;
    }

    &:hover {
        box-shadow: 0 4px 12px rgba(35, 9, 49, 0.08);
        border-color: var(--primary-color-80);
    }

    &:not(.respondent) {
        background-color: #DFDFE0;

        &:hover {
            background-color: #EAEAEB;
        }
    }

    &.respondent {
        background-color: #F4F4F6;

        &:hover {
            background-color: #F9F9FA;
            
        }
    }
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 20px;
}

.nameContainer {
    opacity: 0.4;
}

.lineContainer {
    opacity: 0.8;
    margin-left: 10px;
    //background-color: rgba(0, 0, 0, 0.05);
    background-color: white;
    padding: 2px 4px;

    min-width: 18px;
    border-radius: 8px;
    font-size: 0.85em;

    display: flex;
    justify-content: center;

    span {
        color: #00000080;
        font-weight: 500;
        font-size: 11px;
    }
}
