.descriptionSection {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    //   white-space: unset;
    //   text-wrap: balance;

    /* Для браузеров на основе Webkit (Chrome, Safari) */
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f8f8ff; /* светлый фон трека */
    }

    &::-webkit-scrollbar-thumb {
        background: #f8f8ff; /* цвет бегунка */
        border-radius: 3px;
    }

    /* Для Firefox */
    scrollbar-width: thin;
    scrollbar-color: #f8f8ff #f1f1f100;
}

.solutionList {
    margin: 0px 0px 6px 0px;
    padding-left: 24px;

}
.anchorIcon {
    display: inline-flex;
    align-items: center;
    margin-right: 2px;
    overflow: visible;
}

.vectorIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
