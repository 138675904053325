.mainLayout {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'content sidebar';
    padding: 12px 12px 12px 12px;
    height: 100vh;
    min-height: 100vh;
    background: #f4f5f8;
    min-width: 0;

    @media screen and (max-width: 768px) {
        padding: 6px 6px 6px 6px;
    }
}

.contentWrapper {
    grid-area: content;
    min-width: 0;
}

.mainSidebar {
    grid-area: sidebar;
    min-width: 0;
}
