.tooltipContainer {
    position: relative;
    display: inline-block;
}

.tooltipTrigger {
    margin-left: 8px;
    padding: 2px 6px;
    background-color: #bbdaff55;
    color: #0677ff;
    border-radius: 12px;
    font-size: 12px;
    font-weight: normal;
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transition-property: background-color, color;
    transition-duration: 0s, 0s;
    transition-delay: 0s, 0s;
}

.tooltipContainer:not(:hover) .tooltipTrigger {
    transition-duration: 0.3s, 0s;
    transition-delay: 0s, 0s;
}

.tooltipTrigger:hover,
.tooltipContainer:hover .tooltipTrigger {
    background-color: #3475f6;
    color: #e4ecfc;
}

.tooltipContent {
    position: fixed;
    width: 550px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
    padding: 12px;
    z-index: 1000;

    // Добавляем невидимую область для улучшения взаимодействия
    &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: transparent;
        z-index: -1;
    }

    &.top:before {
        bottom: -20px;
        left: 0;
        right: 0;
    }

    &.bottom:before {
        top: -20px;
        left: 0;
        right: 0;
    }
}

.topArrow {
    position: absolute;
    bottom: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid white;
}

.bottomArrow {
    position: absolute;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
}

.sourceItem {
    display: flex;
    align-items: start;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    padding: 8px;
    border-radius: 12px;
    cursor: pointer;
    transition: box-shadow 0.2s ease;

    &:hover {
        background:#c8cfd72e;
    }
}

.sourceId {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 24px;
    background-color: #bbdaff55;
    color: #0677ff;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    padding: 0 6px;
    margin-right: 8px;
    flex-shrink: 0;
}

.sourceText {
    font-style: italic;
    margin-top: 4px;
    color: #3d3d3d;
}
