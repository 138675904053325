.versionSelector {
    position: relative;
    color: black;
}

.versionButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 4px;
    background-color: #f5f5f5;
    font-size: 14px;
    min-width: 110px;
    justify-content: space-between;

    &:hover {
        //background-color: #e9e9e9;
    }
}

.versionDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 4px;
    background-color: white;
    border-radius: 9px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
    min-width: 100%;
    overflow: hidden;
}

.versionItem {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
    }

    &:first-child {
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
    }

    &:last-child {
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
    }
}

.versionItemActive {
    background-color: #f8f8ff;
    font-weight: 500;
}
