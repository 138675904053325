.headerLayout {
    display: flex;
    align-items: center;
    height: 42px;
    gap: 20px;

    .headerContainer {
        display: flex;
        align-items: center;
        gap: 20px;
        flex: 1;
        min-width: 0;

        .linkBack {
            background-color: white;
            border-radius: 50%;
            font-size: 14px;
            width: 52px;
            height: 52px;
            line-height: 52px;
            text-align: center;
            flex-shrink: 0;

            .linkBackIcon {
                overflow: visible;
                width: 16px;
                height: 16px;
                fill: var(--ant-color-text-secondary);
                transform: translateY(2.5px);

                @media screen and (max-width: 768px) {
                    width: 14px;
                    height: 14.5px;
                }
            }

            &:hover {
                border: 1px solid var(--ant-color-primary);
            }

            @media screen and (max-width: 768px) {
                width: 42px;
                height: 42px;
                line-height: 42px;
            }
        }

        .projectTitle {
            color: var(--ant-color-text) !important;
            font-size: 24px !important;
            line-height: 28px !important;
            font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif !important;
            font-weight: 700 !important;

            @media screen and (max-width: 768px) {
                font-size: 18px !important;
            }
        }

        .exportBtn {
            cursor: pointer;
            margin-left: auto;
        }

        .exportText {
            @media (max-width: 768px) {
                display: none;
            }
        }

        @media screen and (max-width: 768px) {
            gap: 12px;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 6px;
        gap: 12px;
    }
}

.rightControls {
    display: flex;
    align-items: center;
    margin-left: auto;
    //gap: 5px;
}
